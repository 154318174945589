<template>
    <div>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="3">
                <b-form-group label="Дата:" label-cols-md="3" class="bolded-label">
                    <date-picker
                        placeholder="Выберите дату"
                        v-model="dateForInput"
                        format="DD/MM/YYYY"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!---------------------регион------------------------->
                <b-form-group label="Регион (Область, город, район, село):" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать регион"
                        track-by="code"
                        label="text"
                        v-model="reg"
                        :options="listReg"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="chgRegions"
                        ref="regionRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <!-------------Гос. учреждение---------------------->
                <b-form-group label="Гос. учреждение:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        v-model="curComp"
                        track-by="name"
                        label="name"
                        placeholder="Выбрать гос. учреждение"
                        :options="companyBase"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="chgComp"
                        ref="compRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!---------------------вид бюджета------------------------->
                <b-form-group label="Вид бюджета:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать вид бюджета"
                        track-by="code"
                        label="name"
                        v-model="budget_type"
                        :options="list_budget_types"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="chgBudgetTypes"
                        ref="budgetTypeRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <!----------------Администратор программ abp------------------->
                <b-form-group label="Администратор/Программа/Подпрограмма:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        v-model="curAbp"
                        track-by="func"
                        label="name"
                        placeholder="Выбрать адм. программ"
                        :options="abpBase"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="chgAbp"
                        ref="abpRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!-------------Ед. измерения---------------------->
                <b-form-group label="Единица измерения:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать единицу измерения"
                        v-model="curUnit"
                        :options="unit"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        class="w-150px"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <!-------------Специфика---------------------->
                <b-form-group label="Специфика:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        track-by="spf"
                        label="name"
                        placeholder="Выберите специфику"
                        v-model="curSpf"
                        :options="spfBase"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        ref="spfRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
<!--        <b-row class="py-2 bb-row">-->
<!--            <b-button variant="success" @click="downloadRep" :disabled="isLoad">Скачать</b-button>-->
<!--        </b-row>-->
    </div>
</template>

<script>
    import store from "../../../services/store";
    import {Ax} from "@/utils";
    import DatePicker from "vue2-datepicker";

export default {
    name: "treasury-form-542",
    components: { DatePicker },
    data() {
        return {
            obl: null,

            listReg: [],
            reg: null,

            list_budget_types: [],
            budget_type: null,

            date: '',
            dateForInput: null,

            abpBase: [],
            curAbp: null,

            companyBase: [],
            curComp: null,

            spfBase: [],
            curSpf: null,

            languages: ['Казахский', 'Русский'],
            selectedLanguage: null,

            unit: ['тенге', 'тыс. тенге', 'млн. тенге'],
            curUnit: 'тенге',

            isLoad: false,
        }
    },
    async mounted() {
        this.budget_type = this.list_budget_types[0];
        // сегодняшняя дата
        this.dateForInput = new Date();
        this.date = new Date().toLocaleDateString().split('.').reverse().join('-');

        await this.getObl();
    },
    methods: {
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        // this.reg = json.region;
                    });
                await this.loadRegions();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            try {
                this.listReg = [];
                this.reg = null;
                if (this.date.includes('/')) {
                    this.date = this.date.split('/').reverse().join('-')
                }
                const response = await fetch(`/api-py/get_kato_for_542/${this.obl}/${this.date}/${this.usrId}`);
                this.listReg = await response.json();
                if (this.listReg && this.listReg.length > 0) {
                    this.reg = this.listReg[0];
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }

            await this.chgRegions();
        }, // справочник регионов

        async chgRegions() {
            if (this.reg && this.reg.code) {
                // await this.loadBudjetTypes();
                await this.loadCompany();
            }
        },

        async loadCompany() {
            let result = [];
            this.curComp = null;
            this.companyBase = []
            try {
                result = await fetch(`/api-py/get_gu_for_542/${this.date}/${this.reg.code}/${this.usrId}`)
                    .then(response => response.json());
                this.companyBase = result;
            } catch (error) {
                this.companyBase = [];
                this.makeToast('danger', 'Ошибка загрузки Гос. учереждение', error);
            }
            const res = []
            if (result && result.length > 0) {
                for (const el of result) {
                    res.push(this.setNameLang(el, 'gu'));
                }
            }

            if (res && res.length > 1) {
                const firstElem = [{
                    name: 'Все',
                    gu: null
                }]
                this.companyBase = firstElem.concat(res);
            } else {
                this.companyBase = res;
            }

            if (this.companyBase.length > 0) {
                this.curComp = this.companyBase[0];
            }
            await this.chgComp();
        },

        async chgComp() {
            if (this.curComp) {
                // await this.loadAbp();
                await this.loadBudjetTypes();
            }
        },

        async loadBudjetTypes() {
            try {
                if (this.reg) {
                    const response = await fetch(`/api-py/get_type_budjet_for_542/${this.date}/${this.reg.code}/${this.usrId}/${this.curComp.gu}`);
                    this.list_budget_types = await response.json();
                    if (this.list_budget_types && this.list_budget_types.length > 0) {
                        this.budget_type = this.list_budget_types[0];
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }

            await this.chgBudgetTypes();
        },

        async chgBudgetTypes() {
            if (this.budget_type && this.budget_type.code) {
                // await this.loadCompany();
                await this.loadAbp();
            }
        },

        async loadAbp() {
            let result = [];
            this.curAbp = null;
            this.abpBase = []
            if (!this.curComp) {
                return;
            }
            try {
                result = await fetch(`/api-py/get_abp_for_542/${this.date}/${this.reg.code}/${this.budget_type.code}/${this.curComp.gu}/${this.usrId}`)
                    .then(response => response.json());
                this.abpBase = result;
            } catch (error) {
                this.abpBase = [];
                this.makeToast('danger', 'Ошибка загрузки Адм. программа', error);
            }
            const res = []
            for (const el of result) {
                res.push(this.setNameLang(el, 'func'));
            }
            if (res && res.length > 1) {
                const firstElem = [{
                    name: 'Все',
                    func: null
                }]
                this.abpBase = firstElem.concat(res);
            } else {
                this.abpBase = res;
            }

            if (this.abpBase.length > 0) {
                this.curAbp = this.abpBase[0];
            }
            await this.chgAbp();
        },

        async chgAbp() {
            if (this.curAbp) {
                await this.loadSpec();
            }
        },

        async loadSpec() {
            let result = [];
            this.curSpf = null;
            this.spfBase = []
            if (!this.curComp) {
                return;
            }
            try {
                result = await fetch(`/api-py/get_spf_for_542/${this.date}/${this.reg.code}/${this.budget_type.code}/${this.curComp.gu}/${this.curAbp.func}/${this.usrId}`)
                    .then(response => response.json());
                this.spfBase = result;
            } catch (error) {
                this.spfBase = [];
                this.makeToast('danger', 'Ошибка загрузки Специфика', error);
            }
            const res = []
            for (const el of result) {
                res.push(this.setNameLang(el, 'spf'));
            }
            if (res && res.length > 1) {
                const firstElem = [{
                    name: 'Все',
                    spf: null
                }]
                this.spfBase = firstElem.concat(res);
            } else {
                this.spfBase = res;
            }

            if (this.spfBase.length > 0) {
                this.curSpf = this.spfBase[0];
            }
        },

        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },

        downloadRep() {
            try {
                if (
                    !(this.reg && this.reg.hasOwnProperty('code') && this.reg.code) ||
                    !(this.budget_type && this.budget_type.hasOwnProperty('code') && this.budget_type.code) ||
                    !(this.curUnit) ||
                    !(this.date)
                ) {
                    this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                    return
                }
                this.makeToast('info', this.$i18n.t('modules.execution.attention')
                    , this.$i18n.t('modules.execution.treasury_forms_informative_message'));
                this.isLoad = true
                const filter = {
                    region: this.reg.code,
                    budgetType: this.budget_type.code,
                    budgetTypeName: this.budget_type.name,
                    unit: this.curUnit,
                    date: this.date,
                    abp: this.curAbp.func,
                    abpName: this.curAbp.name,
                    gu: this.curComp.gu,
                    guName: this.curComp.name,
                    specific: this.curSpf.spf,
                    specificName: this.curSpf.name
                };
                Ax(
                    {
                        url: '/api-py/treasury-form-xls-rep-542',
                        method: 'POST',
                        responseType: 'blob',
                        data: filter
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Форма 05-42.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
    },
    watch: {
        async dateForInput() {
            this.date = `${this.dateForInput.getFullYear()}-${this.dateForInput.getMonth() + 1}-${this.dateForInput.getDate()}`
            await this.loadRegions();
        }
    },
    computed: {
        usrId() {
            if (!store.state.user.sub) { return null; }
            return store.state.user.sub;
        }
    }
}
</script>

<style scoped>

</style>