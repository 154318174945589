<template>
    <div class="inner-container">
        <div class="section-title budget"><span>Отчеты о кассовом исполнении бюджетных инвестиционных проектов на 1 марта 2022 года</span></div>
            <div class="table-container">
                <b-table
                    :fields="tableFields"
                    :items="bipForm"
                    :tbody-tr-class="rowClass"
                    responsive="true"
                    bordered
                    :filter="filter.name_ru"
                    :filter-included-fields="filter.on"
                    head-variant="light"
                    sticky-header
                    no-border-collapse
                >
                    <template #head(action)="scope">
                        <b-button @click="openAll()">
                            <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                            <i class="icon icon-chevron-circle" v-if="!open"></i>
                        </b-button>
                    </template>
                    <template #cell(action)="data">
                        <b-button v-if="(data.item.childs === 1)" v-model="data.item.open"
                                  @click="openChilds(data.item)">
                            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                        </b-button>
                    </template>
                    <template #head(name_ru)="scope">
                        <div>{{ tableFields[3].label }}</div>
                        <br>
                        <b-form-input
                            id="filter-input"
                            v-model="filter.name_ru"
                            type="search"
                            @click="openAll()"
                        ></b-form-input>
                    </template>
                    <template #cell()="data">
                        <div class="text-right">
                            {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                    <template #cell(name_ru)="data">
                        <div class="text-left">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(abp_id)="data">
                        <div class="text-left">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(region_id)="data">
                        <div class="text-left">
                            {{ data.value }}
                        </div>
                    </template>
                </b-table>
            </div>
    </div>
</template>

<script>
import store from "@/services/store";

export default {
    name: 'BipTable',
    props: {
        year: Number,
        variant: String
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'region_id',
                    label: 'Код НП'
                },
                {
                    key: 'abp_id',
                    label: 'АБП'
                },
                {
                    key: 'name_ru',
                    label: 'Проекты'
                },
                {
                    key: 'plan_god',
                    label: 'План по платежам на год'
                },
                {
                    key: 'plan_period',
                    label: 'План по платежам на отчетный период'
                },
                {
                    key: 'kas_rash',
                    label: 'Кассовые расходы'
                },
                {
                    key: 'execution_period',
                    label: '% исполнения по платежам на период'
                },
                {
                    key: 'execution_year',
                    label: '% исполнения по платежам на год'
                }
            ],
            bipForm: [],
            open: false,
            obl: null,
            region: null,
            filter: {
                name_ru: null,
                search: null,
                on: ['filter', 'name_ru']
            },
            calcFlds: [
                'plan_god',
                'plan_period',
                'kas_rash'
            ],
        };
    },
    async mounted() {
        await this.getObl();
        await this.getBip();
    },
    beforeUpdate() {
        this.bipForm.forEach(row => {
            if (row.childs) {
                row._rowVariant = 'info';
            }
        });
    },
    methods: {
        async getBip() {
            try {
                const response = await fetch('/api-py/budgExecution-tree/'+ this.obl);
                this.bip = await response.json();
                const that = this;
                for (const row of that.bip) {
                    if (row.type === 0) {
                        that.$set(row, 'visible', true);
                    }
                    else {that.$set(row, 'visible', false);}
                    that.$set(row, 'open', false);
                    if (row.childs === 1) {
                        that.$set(row, 'hasChild', true);
                    } else { that.$set(row, 'hasChild', false) }

                    if ([0, 1].includes(row.type)) {
                        that.calcFlds.forEach(field => {
                            Object.defineProperty(row, field, {
                                get: function () {
                                    return that.reSum(row, field);
                                }
                            })
                        })
                    }
                    Object.defineProperty(row, 'execution_period', {
                        get: function () {
                            const rate = parseFloat(row.kas_rash/row.plan_period*100);
                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(2)));
                        }
                    })
                    Object.defineProperty(row, 'execution_year', {
                        get: function () {
                            return parseFloat((row.kas_rash/row.plan_god*100).toFixed(2));
                        }
                    })
                    that.$set(that.bipForm, that.bipForm.length, row);

                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getBip()', error.toString());
            }
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        openAll() {
            this.open = !this.open;
            for (const row of this.bipForm) {
                if (row.type > 0) {
                    row.visible = this.open;
                    if ([1].includes(row.childs)) {
                        row.open = this.open;
                    }
                }
                else {
                    row.open = this.open;
                }
            }
        },
        openChilds(parent, bool) {
            parent.open = !parent.open;
            for (const row of this.bipForm) {
                if (parent.type === 0 && row.type === 1 && parent.region === row.region) {
                    row.visible = parent.open;
                }
                if (parent.type === 1 && row.type === 2 && parent.region === row.region && parent.abp_id === row.abp){
                    row.visible = parent.open;
                }
                if (!parent.open && parent.type === 0 && parent.region === row.region){row.visible = parent.open}
                if (row.type === 0){row.visible = true;}

            }
        }, // открывает/закрывает ветку до конечного элемента
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        reSum(parent, field) {
            let sum = 0;
            if (parent.type === 0) {
                this.bipForm.forEach(row => {
                    if ((row.type === 1) &&
                        parent.region === row.region) {
                        sum += parseFloat(row[field]);
                    }
                })
            }
            if (parent.type === 1) {
                this.bipForm.forEach(row => {
                    if ((row.type === 2) &&
                        parent.region === row.region
                        && parent.abp_id === row.abp) {
                        sum += parseFloat(row[field]);
                    }
                })
            }
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    },
    computed: {}
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>