<template>
    <div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <br>
                    <b-form-group label="Наименование отчета " class="bolded-label">
                        <multiselect
                            v-model="curReport"
                            :options="reportList"
                            track-by="code"
                            label="name_ru"
                            placeholder="Выберите отчет"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="callFilterGetObl"
                        >
                        </multiselect>
                    </b-form-group>
                    <filter-all
                        :repCode="curReport"
                        @loadData="reportOpen7"
                        @loadApplicationForm3="reportOpen3"
                        @loadApplicationForm2="reportOpen2"
                        ref="filter"
                    >
                    </filter-all>
                </div>
            </b-dropdown>
            <b-button v-if="curReport && curReport.code === 'PR3'" :variant="buttonVar" :disabled="!isSave" @click="repSaveApp3">Сохранить</b-button>
            <b-button v-if="curReport && curReport.code === 'PR2'" :variant="buttonVar" :disabled="!isSave" @click="repSaveApp2">Сохранить</b-button>
        </div>
        <br><br>
        <div>
            <application-form2 v-if="curReport && curReport.code === 'PR2'"  @reasonChanged="updateIsSave" ref="applicationForm2"></application-form2>
            <application-form3 v-if="curReport && curReport.code === 'PR3'"  @reasonChanged="updateIsSave" ref="applicationForm3"></application-form3>
            <application-form7 v-if="curReport && curReport.code === 'PR7'" ref="applicationForm7"></application-form7>
        </div>
    </div>
</template>


<script>
import applicationForm2 from "@/modules/budget/monitoring/report-components/application-form-2";
import applicationForm3 from "@/modules/budget/monitoring/report-components/application-form-3";
import applicationForm7 from "@/modules/budget/monitoring/report-components/application-form-7";
import filterAll from "@/modules/budget/monitoring/report-components/filter";

export default {
    components: {filterAll, applicationForm7, applicationForm3, applicationForm2},
    name: 'report-fulfill',
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 1,
            reportList: {},
            curReport: null,
            isOpen: false,
            padding: {},
            isSave: false
        };
    },

    async mounted() {
        await this.getReportList();
    },
    computed: {
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        }
    },
    methods: {
        updateIsSave() {
            this.isSave = true;
        },
        repSaveApp3() {
            this.$refs.applicationForm3.repSave();
        },
        repSaveApp2() {
            this.$refs.applicationForm2.repSave();
        },
        callFilterGetObl() {
            this.$refs.filter.getObl();
            this.$refs.filter.period = '';
            this.$refs.filter.currYear = 2023;
        },
        async getReportList() {
            const response = await fetch(`/api-py/get-report-list`);
            this.reportList = await response.json();
        },
        reportOpen2(reg, reg_name, period, date_to, qvartal, year){
            this.$refs.applicationForm2.$emit('loadApplication2', reg,  reg_name, period, date_to, qvartal, year);
        },
        reportOpen3(reg, reg_name, period, date_to, qvartal, year){
            this.$refs.applicationForm3.$emit('loadApplication3', reg,  reg_name, period, date_to, qvartal, year);
        },
        reportOpen7(reg, reg_name, period, date_to, month, year, abp, prg, ppr, spf) {
            this.$refs.applicationForm7.$emit('loadData', reg,  reg_name, period, date_to, month, year, abp, prg, ppr, spf);
        },
        repOpen(data) {
            this.isOpen = data;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

    },
};
</script>

<style scoped>

</style>