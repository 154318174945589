<template>
    <div>
        <div v-if="!isOpen">
            <hr>
            <!-------------Периодичность---------------------->
            <b-form-group v-if="repCode.code === 'R542' || repCode.code === 'PR7'"
                label="Периодичность">
                <multiselect
                    placeholder="Выберите период"
                    v-model="period"
                    :options="filteredPeriods"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <b-form-group v-if="period.code === 1 || period.code === 12 || period.code === 4 || repCode.code === 'PR3'
            || repCode.code === 'PR2'" label="Год">
                <multiselect
                    v-model="currYear"
                    placeholder="Выбрать год"
                    :options="filterYears()"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    ref="filterYear"
                    @input="LoadForPR7">
                </multiselect>
            </b-form-group>
            <b-form-group v-if="period.code === 1" label="Месяц">
                <multiselect
                    placeholder="Выберите месяц"
                    v-model="selectedMonth"
                    :options="months"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="LoadForPR7"/>
            </b-form-group>
            <b-form-group v-if="period.code === 4 || repCode.code === 'PR3' || repCode.code === 'PR2'" label="Квартал">
                <multiselect
                    placeholder="Выберите квартал"
                    v-model="selectedQvartal"
                    :options="qvartal"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"/>
            </b-form-group>
            <b-form-group v-if="period.code === 0" label-cols-md="3" label="Дата:">
                <input type="date" v-if="period.code === 0"
                       placeholder="Выберите дату"
                       v-model="firstDateTo"
                       format="DD/MM/YYYY"
                       @input="LoadForPR7"
                />
            </b-form-group>
            <hr>
            <!---------------------Като------------------------->
            <b-form-group label="Регион (Область, город, район)">
                <multiselect
                    placeholder="Выберите регион"
                    track-by="code"
                    label="text"
                    v-model="reg"
                    :options="listReg"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    ref="regionRef"
                    @input="LoadForPR7">
                </multiselect>
            </b-form-group>
            <!---------------------Разрезность------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Разрезность">
                <multiselect
                    placeholder="Выберите разрезность"
                    v-model="curCut"
                    :options="cutnessList"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------ВЗК------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="ВЗК">
                <multiselect
                    placeholder="Выберите ВЗК"
                    v-model="selecteds"
                    :options="r_options"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Вид программы------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Вид программы">
                <multiselect
                    placeholder="Вид программы"
                    v-model="develop"
                    :options="developes"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------АБП------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="АБП:">
                <multiselect
                    placeholder="Выберите АБП"
                    track-by="text"
                    label="text"
                    v-model="selectedAbp"
                    :options="abpList"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadSpf">
                    >
                </multiselect>
            </b-form-group>
            <!---------------------ПРГ------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="Программа:">
                <multiselect
                    placeholder="Выберите программу"
                    track-by="text"
                    label="text"
                    v-model="selectedPrg"
                    :options="selectedAbp ? selectedAbp.prgList : []"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadSpf">
                    >
                </multiselect>
            </b-form-group>
            <!---------------------ППР------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="Подпрограмма:">
                <multiselect
                    placeholder="Выберите подпрограмму"
                    track-by="text"
                    label="text"
                    v-model="selectedPpr"
                    :options="selectedPrg ? selectedPrg.pprList : []"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadSpf"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Специфика------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="Специфика:">
                <multiselect
                    placeholder="Выберите специфику"
                    track-by="text"
                    label="text"
                    v-model="selectedSpf"
                    :options="spfList"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <hr>
            <div class="filter-actions">
                <b-button style="display: inline-flex" variant="secondary" :disabled="isLoad" @click="paramClear">
                    Сбросить
                </b-button>
                <b-button style="display: inline-block" variant="primary" :disabled="isLoad" @click="loadData">
                    Применить
                </b-button>
            </div>
            <br>
        </div>

        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />

    </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";

export default {
    name: "filter-all",
    props: {
        repCode: null
    },
    components: {'loading': VueElementLoading},
    data() {
        return {
            obl: null,
            listReg: [],
            listRegion: [],
            curParams: {},
            region: null,
            reg: null,
            dateFrom: null,
            modalForm: false,
            reasonForm: false,
            currYear: new Date().getFullYear(),
            firstDateTo: `${new Date(new Date().getFullYear(), new Date().getMonth(), 0)}`,
            firstDateFrom: `${new Date(new Date().setFullYear(this.firstDateTo), new Date().getMonth() - 12, 1)}`,
            curDate: null,
            dateTo: null,
            isLoad: false,
            isSave: false,
            isOpen: false,
            isNew: false,
            listReports: [],
            arr: [],
            changeRow: false,
            curReport: [],
            curRegion: 0,
            newReport: {id: 0, name_ru: '', name_kk: '', name_en: ''},
            modalDataIsSavable: null,
            loadingF: false,
            loading: false,
            regName: 'Все',
            arrForm: [],
            curRow: [],
            regionForm: null,
            abpList: [],
            prgList: [],
            pprList: [],
            spfList: [],
            selectedAbp: null,
            selectedPrg: null,
            selectedPpr: null,
            selectedSpf: null,
            abpForm: null,
            prgForm: null,
            pprForm: null,
            spfForm: null,
            repdate: '',
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь',
                'Октябрь', 'Ноябрь', 'Декабрь'],
            selectedMonth: [],
            selectedQvartal: [],
            qvartal: ['Ⅰ квартал', 'Ⅱ квартал', 'Ⅲ квартал', 'Ⅳ квартал'],
            curCut: {text: 'Регион', code: 0},
            cutnessList: [
                {text: 'Регион', code: 0},
                {text: 'АБП', code: 1},
                {text: 'Программа', code: 2},
                {text: 'Подпрограмма', code: 3},
                {text: 'Специфика', code: 4}
            ],
            selecteds: {text: 'с ВЗК', code: 0},
            r_options: [
                {text: 'с ВЗК', code: 0},
                {text: 'без ВЗК', code: 1},
                {text: 'ВЗК', code: 2}
            ],
            developes: [
                {text: 'Текущая', code: 0},
                {text: 'Развития', code: 1},
                {text: 'Все', code: 2}
            ],
            develop: {text: 'Все', code: 2},
            period: '',
            periods: [
                {text: 'Месяц', code: 1},
                {text: 'Год', code: 12},
                {text: 'Квартал', code: 4},
                {text: 'Дата', code: 0}
            ]
        };
    },

    async mounted() {
        await this.getObl();
        if (this.repCode.code === 'PR7') {
            await this.listLoads();
            await this.loadSpf();
        }

    },

    created() {
        this.firstDateFrom = new Date(this.firstDateFrom);
        this.firstDateTo = new Date(this.firstDateTo);
    },
    computed: {
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        },
        filteredPeriods() {
            if (this.repCode.code === 'PR7') {
                return this.periods.filter(period => period.text !== 'Год' && period.text !== 'Квартал');
            }
            // if (this.repCode.code === 'PR3' || this.repCode.code === 'PR2') {
            //     return this.periods.filter(period => period.text !== 'Месяц' && period.text !== 'Год' && period.text !== 'Промежуточная');
            // }
            if (this.repCode.code === 'R542') {
                return this.periods.filter(period => period.text !== 'Квартал');
            } else {
                return this.periods;
            }
        }
    },

    methods: {
        async LoadForPR7(){
            if (this.repCode.code === 'PR7') {
                await this.listLoads();
                await this.loadSpf();
            }
        },
        async period_for_all() {
            const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
            if (this.period.code === 0) {
                this.repdate = this.firstDateTo;
            } else if (this.period.code === 12) {
                this.repdate = `${this.currYear}-12-31`;
            } else if (this.period.code === 1) {
                const month_num = months.indexOf(this.selectedMonth) + 1;
                const last_day = new Date(this.currYear, month_num, 0).getDate();
                this.repdate = `${this.currYear}-${month_num.toString().padStart(2, '0')}-${last_day.toString().padStart(2, '0')}`;
            }
            return this.repdate;
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
            if (this.repCode !== null) {
                try {
                    const response = await fetch('/api-py/get_kato_fulfill/' + this.obl + '/' + this.repCode.code + '/' + this.$store.getters.user_uuid);
                    this.listReg = await response.json();
                    this.reg = this.listReg[0];

                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
                }
            }

        }, // справочник регионов
        // async loadRegions_all() {
        //     try {
        //         const response = await fetch('/api-py/get_kato_all/' + this.obl);
        //         const items = await response.json();
        //         this.listRegion = items.listRegion;
        //         this.region = this.listRegion[0];
        //     } catch (error) {
        //         this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
        //     }
        // },
        async listLoads() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    this.loadingF = true;
                    this.curParams = {region: this.reg.code, date_to: this.repdate};
                    const response = await fetch('/api-py/get-abp-prg-ppr7/' + JSON.stringify(this.curParams));
                    const items = await response.json();
                    await this.loadFilters(new Map(Object.entries(items.abpList)));
                    this.loadingF = false;
                } catch (error) {
                    this.makeToast('warning', 'Предупреждение', 'Не все параметры заданы.');
                }
            }
        },
        async loadFilters(map) {
            try {
                this.abpList = [];
                for (const [key, value] of map.entries()) {
                    this.abpList.push(value);
                }
                this.selectedAbp = this.abpList[0];
                this.prgList = this.abpList[0].prgList;
                this.selectedPrg = this.prgList[0];

                this.pprList = this.abpList[0].prgList[0].pprList;
                this.selectedPpr = this.pprList[0];

            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки loadFilters', error.toString());
            }
        },
        async loadSpf() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    this.curParams = {
                        date_to: this.repdate,
                        region: this.reg.code,
                        abp: this.selectedAbp.abp,
                        prg: this.selectedPrg.prg,
                        ppr: this.selectedPpr.ppr
                    };
                    const response = await fetch('/api-py/get_spf_list/' + JSON.stringify(this.curParams));
                    const items = await response.json();
                    this.spfList = items.spfList;
                    this.selectedSpf = this.spfList[0];
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса специфики', error.toString());
                }
            }
        },

        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        paramClear() {
            this.firstDateTo = null;
            this.reg = null;
            this.currYear = null;
            this.selectedQvartal = null;
            this.selecteds = null;
            this.develop = null;
        },
        async getReportList() {
            this.listReports = []
            const params = {repCode: this.repCode}
            const response = await fetch(`/api-py/get-report-data-2/` + JSON.stringify(params));
            this.listReports = await response.json();
        },
        filterYears() {
            const now = new Date();
            const yearNow = now.getFullYear();
            const years = [];
            for (let i = yearNow; i >= yearNow - 10; i--) {
                if (i >= 2020) {
                    years.push(i);
                }
            }
            return years;
        },
        async loadData() {
            if (this.repCode.code === 'PR2') {
                this.$emit('loadApplicationForm2',
                    this.reg.code, this.reg.name_ru,
                    4, this.firstDateTo, this.selectedQvartal, this.currYear);
            }
            if (this.repCode.code === 'PR3') {
                this.$emit('loadApplicationForm3',
                    this.reg.code, this.reg.name_ru,
                    4, this.firstDateTo, this.selectedQvartal, this.currYear);
            }
            if (this.repCode.code === 'PR7') {
                this.$emit('loadData',
                    this.reg.code, this.reg.name_ru,
                    this.period.code, this.firstDateTo, this.selectedMonth, this.currYear,
                    this.selectedAbp.abp, this.selectedPrg.prg, this.selectedPpr.ppr, this.selectedSpf.spf);
            }

        },

        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab
        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой


    }
}
</script>
<style>
input[type="date"] {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 15px;
    border-radius: 5px;
}
</style>

