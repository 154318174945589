<template>
    <div>
        <b-row class="py-2 bb-row">
            <!-------------Период---------------------->
            <b-col cols="12" md="3">
                <b-form-group label="Дата с:" label-cols-md="3" class="bolded-label">
                    <div>
                        <date-picker
                            placeholder="Выберите дату"
                            v-model="dateFromForInput"
                            format="DD/MM/YYYY"
                        />
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
                <b-form-group label="Дата по:" label-cols-md="3" class="bolded-label">
                    <date-picker
                        placeholder="Выберите дату"
                        v-model="dateToForInput"
                        format="DD/MM/YYYY"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!---------------------Като------------------------->
                <b-form-group label="Регион (Область, город, район, село):" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать регион"
                        track-by="code"
                        label="text"
                        v-model="reg"
                        :options="listReg"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        ref="regionRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!-------------Ед. измерения---------------------->
                <b-form-group label="Единица измерения:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать единицу измерения"
                        v-model="curUnit"
                        :options="unit"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        class="w-150px"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
<!--        <b-row class="py-2 bb-row">-->
<!--            <b-button variant="success" :disabled="isLoad" @click="downloadRep">Скачать</b-button>-->
<!--        </b-row>-->
    </div>
</template>

<script>
import store from "../../../services/store";
import {Ax} from '@/utils';
import DatePicker from 'vue2-datepicker';

export default {
    name: "treasury-form-211",
    components: { DatePicker },
    data() {
        return {
            obl: null,
            listReg: [],
            reg: null,
            dateFromForInput: null,
            dateFrom: null,
            dateToForInput: null,
            dateTo: null,
            // languages: ['Казахский', 'Русский'],
            // selectedLanguage: 'Русский',
            unit: ['тенге', 'тыс. тенге', 'млн. тенге'],
            curUnit: 'тенге',
            isLoad: false,
        }
    },
    async mounted() {
        // сегодняшняя дата
        this.dateFromForInput = new Date();
        this.dateFrom = new Date().toLocaleDateString().split('.').reverse().join('-');

        // послд день текущего года
        this.dateToForInput = new Date(`${new Date().getFullYear()}-12-31`);
        this.dateTo = new Date().getFullYear() + '-12-31';
        await this.getObl();
    },
    methods: {
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });
                await this.loadRegions();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            try {
                this.listReg = [];
                this.reg = null;
                if (this.dateFrom.includes('/')) {
                    this.dateFrom = this.dateFrom.split('/').reverse().join('-')
                }
                if (this.dateTo.includes('/')) {
                    this.dateTo = this.dateTo.split('/').reverse().join('-')
                }
                const response = await fetch(`/api-py/get_kato_for_211/${this.obl}/${this.dateFrom}/${this.dateTo}/${this.usrId}`);
                this.listReg = await response.json();
                if (this.listReg && this.listReg.length > 0) {
                    this.reg = this.listReg[0];
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов

        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },

        downloadRep() {
            try {
                if (
                    !(this.reg && this.reg.hasOwnProperty('code') && this.reg.code) ||
                    !(this.curUnit) ||
                    !(this.dateFrom) ||
                    !(this.dateTo)
                ) {
                    this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                    return
                }
                this.makeToast('info', this.$i18n.t('modules.execution.attention')
                    , this.$i18n.t('modules.execution.treasury_forms_informative_message'));
                this.isLoad = true
                const filter = {
                    region: this.reg.code,
                    unit: this.curUnit,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo
                };
                Ax(
                    {
                        url: '/api-py/treasury-form-xls-rep-211/' + JSON.stringify(filter),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Форма 02-11.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
    },
    watch: {
        async dateFromForInput() {
            this.dateFrom = `${this.dateFromForInput.getFullYear()}-${this.dateFromForInput.getMonth() + 1}-${this.dateFromForInput.getDate()}`
            await this.loadRegions();
        },
        async dateToForInput() {
            this.dateTo = `${this.dateToForInput.getFullYear()}-${this.dateToForInput.getMonth() + 1}-${this.dateToForInput.getDate()}`
            await this.loadRegions();
        }
    },
    computed: {
        usrId() {
            if (!store.state.user.sub) { return null; }
            return store.state.user.sub;
        }
    }
}
</script>

<style scoped>

</style>