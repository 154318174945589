<template>
    <div class="row card-container">
        <v-widget v-if="outcomeKat.id" :data="outcomeKat" :section="{}" />
        <v-widget v-if="outcomeMonth.id" :data="outcomeMonth" :section="{}" />
        <v-widget v-if="outcomeRegion.id" :data="outcomeRegion" :section="{}" />
    </div>
</template>

<script>
import VWidget from '@/modules/widget-yard/c-widget/v-widget';

export default {
    components: {
        'v-widget': VWidget
    },
    name: 'Outcome',
    data() {
        return {
            year: new Date().getFullYear(),
            // month: new Date().getMonth() - 1,
            region: 450101,
            outcomeKat: {},
            outcomeMonth: {},
            outcomeRegion: {}
        };
    },
    methods: {
        async getOutcomeKat() {
            const response = await fetch(`/api-py/get-request-budg-kat-rash/${this.year}/09/${this.region}`);
            const data = await response.json();
            this.outcomeKat = {
                type: 'chart-monitoring',
                id: 211,
                title: data.shortIndicator,
                data: data.items,
                unit: data.unit,
                periodicity: data.periodicity,
                indicatorKeys: [],
                vertical: false
            };
        },
        async getOutcomeMonth() {
            const response = await fetch(`/api-py/get-request-budg-month-rash/${this.year}/09/${this.region}`);
            const data = await response.json();
            this.outcomeMonth = {
                type: 'chart-monitoring',
                id: 212,
                title: data.shortIndicator,
                data: data.items,
                unit: data.unit,
                periodicity: data.periodicity,
                indicatorKeys: [],
                vertical: true
            };
        },
        async getOutcomeRegion() {
            const response = await fetch(`/api-py/get-request-budg-region-rash/${this.year}/09/${this.region}`);
            const data = await response.json();
            this.outcomeRegion = {
                type: 'chart-monitoring',
                id: 213,
                title: data.shortIndicator,
                data: data.items,
                unit: data.unit,
                periodicity: data.periodicity,
                indicatorKeys: [],
                vertical: false
            };
        }
    },
    created() {
        if (this.code === 23) {
            this.region = 150101;
        }
        this.getOutcomeKat();
        this.getOutcomeMonth();
        this.getOutcomeRegion();
    }
};
</script>