








import {Vue, Component, Prop} from "vue-property-decorator";
import BaseUserLink from "@/modules/user-management/base-user-link";
import {BTab} from "bootstrap-vue";

export interface ISetting {
    code: string,
    title: string,
    src: string,
    width: string,
    height: string,
    allowFullScreen: boolean
}

@Component({
    components: {

    }
})
export default class PowerBiIframe extends Vue {
    private setting: null | ISetting = null;
    @Prop() private code: string | undefined;
    private async loadData() {
        if (this.code) {
            const response = await fetch('/api-py/get-power-bi-frame/' + this.code);
            if (response.status == 200) {
                const result = await response.json() as ISetting;
                this.setting = result
                console.debug(this.setting)
            }
        }
    }
    mounted() {
        this.loadData()
    }
}
