<template>
    <div>
        <div class="table-container">
            <!-- хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="dateTo !== null" >{{ 'Дата' }} : {{ dateTo}}</span>
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null" >{{ reg }} - {{ reg_name }}</span>
                <span class="item-breadcrumb" v-if="abp !== null && abp !== '000'" @click="openFilterByRef('filter')" >{{ 'АБП' }} : {{ abp }}</span>
                <span class="item-breadcrumb" v-if="prg !== null && prg !== '000'">{{ 'БП' }} : {{ prg }}</span>
                <span class="item-breadcrumb" v-if="ppr !== null && ppr !== '000'">{{ 'БПП' }} : {{ ppr }}</span>
                <span class="item-breadcrumb" v-if="spf !== null && spf !== '000'">{{ 'Специфика' }} : {{ spf }}</span>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="arr"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="1"></b-th>
                        <b-th colspan="4" class="text-center">{{ 'Код бюджетной классификации' }}</b-th>
                        <b-th colspan="2"></b-th>
                        <b-th colspan="2" class="text-center">{{ 'Сводный план на отчетный период' }}</b-th>
                        <b-th colspan="5"></b-th>
                    </b-tr>
                </template>
                <template #top-row="data">
                    <td v-for="(field, c) in data.fields"
                        :key="c + field" class="td-numbering">
                        <div class="text-center" v-if="c !== 0">
                            {{ c }}
                        </div>
                    </td>
                </template>
                <template #top-row="data" v-if="totals !== null">
                    <td class="text-center" colspan="6">{{ totals.name_ru }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row5)) }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row6)) }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row7)) }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row8)) }}</td>
                    <td class="text-left">{{ totals.row6 > 0 ? $n(toNum((totals.row8 / totals.row6) * 100)) : 0 }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row8 - totals.row6)) }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row11)) }}</td>
                    <td class="text-left">{{ $n(toNum(totals.row11 - totals.row7)) }}</td>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type === 3" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 4)"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type === 4" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 5)"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type === 5" class="text-left">{{ data.value }}</div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 6" class="text-left">{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-left">{{ $n(data.value) }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div class="text-left">
                        {{ data.item.name_ru }}
                    </div>
                </template>

            </b-table>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep">Приложение 7</b-dropdown-item>
            </b-dropdown>
        </div>
    </div>


</template>
<script>
import store from "../../../../services/store";
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
function calculateRow9(arr) {
    arr.forEach(item => {
         item.row9 = item.row6 === 0 ? 0 : (item.row8 / item.row6 * 100).toFixed(1);
        if (item.child && item.child.length > 0) {
            calculateRow9(item.child);
        }
    });
}

export default {
    name: "application-form-7",
    components: {'loading': VueElementLoading},
    data() {
        return {
            obl: null,
            listReg: [],
            curParams: {},
            dateFrom: null,
            modalForm: false,
            reasonForm: false,
            firstDateFrom: `${new Date().getFullYear()}` + '-01' + '-01',
            firstDateTo: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()}`,
            curDate: null,
            dateTo: null,
            isLoad: false,
            isSave: false,
            isOpen: false,
            isNew: false,
            repName: 'Приложение_7',
            tableFields: [
                {
                    key: 'action',
                    label: '',
                    thClass: null,
                    class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'spf',
                    label: 'СП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'row5',
                    label: 'Скорректированный бюджет на отчетный финансовый год'
                },
                {
                    key: 'row6',
                    label: 'по платежам'
                },
                {
                    key: 'row7',
                    label: 'по обязательствам'
                },
                {
                    key: 'row8',
                    label: 'Оплаченные обязательства'
                },
                {
                    key: 'row9',
                    label: '% исполнения'
                },
                {
                    key: 'row10',
                    label: 'Сумма неисполнения'
                },
                {
                    key: 'row11',
                    label: 'Принятые обязательства'
                },
                {
                    key: 'row12',
                    label: 'Сумма непринятых обязательств'
                }
            ],
            listReports: [],
            arr: [],
            abp: null,
            prg: null,
            ppr: null,
            spf: null,
            reg: null,
            reg_name: null,
            totals: null,
            changeRow: false,
            curReport: [],
            curRegion: 0,
            newReport: {id: 0, name_ru: '', name_kk: '', name_en: ''},
            modalDataIsSavable: null,
            loadingF: false,
            loading: false,
            abpName: 'Все',
            prgName: 'Все',
            pprName: 'Все',
            spfName: 'Все',
            arrForm: [],
            arrFormTemp: [],
            arrDataMap: new Map(),
            curRow: []
        };
    },
    beforeUpdate() {
        for (const row of this.arr) {
            if ([3].includes(row.type)) {
                row._rowVariant = 'primary';
            }
            if ([4, 5].includes(row.type)) {
                row._rowVariant = "info";
            }
        }
    },

    async mounted() {
        // await this.getObl();
        // await this.listLoads();
        // await this.loadSpf();
    },
    created() {
        this.$on('loadData', (reg, reg_name, period, date_to, month, year, abp, prg, ppr, spf) => {
            this.reportOpen7(reg, period, date_to, month, year, abp, prg, ppr, spf);
            this.reg = reg;
            this.reg_name = reg_name;
            this.abp = abp;
            this.prg = prg;
            this.ppr = ppr;
            this.spf = spf;
        });
    },
    computed: {
        labelText() {
            return this.curReport.name_ru;
        },
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        }
    },

    methods: {

        // async listLoads() {
        //     try {
        //         if (this.firstDateFrom !== null && this.firstDateTo !== null && this.reg !== null)
        //             this.loadingF = true;
        //         this.curParams = {
        //             date_from: moment(this.firstDateFrom).format('YYYY-MM-DD'),
        //             date_to: moment(this.firstDateTo).format('YYYY-MM-DD'),
        //             region: this.reg.code
        //         };
        //         const response = await fetch('/api-py/get-abp-prg-ppr7/' + JSON.stringify(this.curParams));
        //         const items = await response.json();
        //         await this.loadFilters(new Map(Object.entries(items.abpList)));
        //         // await this.loadSpf();
        //         this.loadingF = false;
        //     } catch (error) {
        //         this.makeToast('danger', 'Предупреждение', 'Не все параметры заданы.');
        //     }
        // },
        // async loadFilters(map) {
        //     try {
        //         this.abpList = [];
        //         for (const [key, value] of map.entries()) {
        //             this.abpList.push(value);
        //         }
        //         this.selectedAbp = this.abpList[0];
        //         this.prgList = this.abpList[0].prgList;
        //         this.selectedPrg = this.prgList[0];
        //
        //         this.pprList = this.abpList[0].prgList[0].pprList;
        //         this.selectedPpr = this.pprList[0];
        //
        //     } catch (error) {
        //         this.makeToast('danger', 'Ошибка загрузки loadFilters', error.toString());
        //     }
        //
        // },
        // async loadSpf() {
        //
        //     try {
        //         if (this.firstDateFrom !== null && this.firstDateTo !== null && this.reg !== null)
        //             this.curParams = {
        //                 date_from: moment(this.firstDateFrom).format('YYYY-MM-DD'),
        //                 date_to: moment(this.firstDateTo).format('YYYY-MM-DD'),
        //                 region: this.reg.code,
        //                 abp: this.selectedAbp.abp,
        //                 prg: this.selectedPrg.prg,
        //                 ppr: this.selectedPpr.ppr
        //             };
        //         const response = await fetch('/api-py/get_spf_list/' + JSON.stringify(this.curParams));
        //         const items = await response.json();
        //         this.spfList = items.spfList;
        //         this.selectedSpf = this.spfList[0];
        //     } catch (error) {
        //         this.makeToast('danger', 'Ошибка запроса специфики', error.toString());
        //     }
        //
        // },
        openFilterByRef(refName) {
            const filter = this.$refs.filter;
            filter.openFilterByRef(refName);
        },

        labelTxt(item) {
            return "с " + moment(item.date_from).format('DD.MM.YYYY') + " по " + moment(item.date_to).format('DD.MM.YYYY');
        },
        lblTxt(item) {
            return moment(item.repdate).format('DD.MM.YYYY HH:mm');
        },
        nameTxt(item) {
            if (this.$i18n.locale === 'ru') {
                return item.name_ru;
            } else if (this.$i18n.locale === 'kk') {
                return item.name_kk;
            } else if (this.$i18n.locale === 'en') {
                return item.name_en;
            }
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        paramClear() {
            this.firstDateFrom = null;
            this.firstDateTo = null;
            this.reg = null;
            this.abpList = [];
            this.prgList = [];
            this.pprList = [];
            this.spfList = [];
        },
        async getReportList() {
            this.listReports = []
            const params = {repCode: this.repCode}
            const response = await fetch(`/api-py/get-report-data-2/` + JSON.stringify(params));
            const data = await response.json();
            this.listReports = data;
        },

        async reportOpen7(reg, period, date_to, month, year, abp, prg, ppr, spf) {
            this.loading = true;
            this.curParams = {
                region: reg,
                period: period,
                date_to: date_to,
                month: month,
                year: year,
                abp: abp,
                prg: prg,
                ppr: ppr,
                spf: spf
            };
            const response = await fetch(`/api-py/get-fulldata-7/` + JSON.stringify(this.curParams));
            const data = await response.json();
            this.arr = data.arr;
            this.totals = data.totals;
            this.dateTo  = data.period;
            calculateRow9(this.arr);
            this.toOpen();
            await this.prepareForm();
            this.loading = false;
        },

        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        onReasonChange(item) {
            this.$set(item, 'onSave', true);
            this.isSave = true;
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true
                Ax(
                    {
                        url: '/api-py/fulfill_report7/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xls');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab
        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        async delete(item, index) {
            try {
                const response = await fetch('/api-py/delete-report-2/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.listReports.splice(index, 1);
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данных
        deleteReport(row, index) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление данного отчета?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.delete(row, index);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },
        onChangeRecord(value) {
            this.$nextTick(() => {
                this.$set(this, 'isSave', value.length > 0);
            });
        },
        updateABP() {
            try {
                this.selectedPrg = this.selectedAbp.prgList[0];
                this.selectedPpr = this.selectedAbp.prgList[0].pprList[0];
                this.isLoading = false;
            } finally {
            }
        },
        updatePRG() {
            this.selectedPpr = this.selectedPrg.pprList[0];
            this.isLoading = false;
        },
        updatePPR() {
            this.isLoading = false;
        },
        updateSPF() {
            this.isLoading = false;
        },
        createTable(elem, parentId) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'parent_id', parentId);
            that.$set(item, 'visible', true);

            const par = that.arrDataMap.get(item.parent_id);
            if (par) {
                if (par.hasOwnProperty('newchild')) {
                    that.$set(par.newchild, par.newchild.length, item);
                } else {
                    that.$set(par, 'newchild', []);
                    that.$set(par.newchild, par.newchild.length, item);
                }
            }
            Object.defineProperty(item, 'parent', {
                get: function () {
                    const par = that.arrDataMap.get(item.parent_id);
                    if (par) {
                        return par;
                    }
                    return null;
                }
            });

            that.$set(item, 'index', that.arrFormTemp.length);
            that.$set(that.arrFormTemp, that.arrFormTemp.length, item);
            that.arrDataMap.set(item.id, item);
            if (item.hasOwnProperty('child') && (item.child.length > 0)) {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);
            }

            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)
        async prepareForm() {
            this.arrForm = [];
            this.arrFormTemp = [];
            this.arrDataMap = new Map();
            for (const item of this.arr) {
                await this.createTable(item, 0);
            }
            for (const item of this.arrFormTemp) {
                if (item.type == 2) {
                    item.open = false;
                    this.arrForm.push(item)
                }
            }
        }, // подготовка отображения данных
        compare_spf(a, b) {
            if (a.spf < b.spf) {
                return -1;
            }
            if (a.spf > b.spf) {
                return 1;
            }
            return 0;
        },
        compare_ppr(a, b) {
            if (a.ppr < b.ppr) {
                return -1;
            }
            if (a.ppr > b.ppr) {
                return 1;
            }
            return 0;
        },
        compare_prg(a, b) {
            if (a.prg < b.prg) {
                return -1;
            }
            if (a.prg > b.prg) {
                return 1;
            }
            if (a.ppr === undefined) {
                return -1;
            }
            if (b.ppr === undefined) {
                return 1;
            }
            return 0;
        },
        compare_abp(a, b) {
            if (a.abp < b.abp) {
                return -1;
            }
            if (a.abp > b.abp) {
                return 1;
            }
            if (a.prg === undefined) {
                return -1;
            }
            if (b.prg === undefined) {
                return 1;
            }
            return 0;
        },
        compare_null(a, b) {
            if ((a.abp === undefined) || (b.abp !== undefined)) {
                return -1;
            }
            return 1;
        },
        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);
            if (parent.child) {
                let dat = parent.child;
                dat = dat.sort(this.compare_spf);
                dat = dat.sort(this.compare_ppr);
                dat = dat.sort(this.compare_prg);
                dat = dat.sort(this.compare_abp);
                dat = dat.sort(this.compare_null);
                for (const row of dat) {
                    if ((parent.id === row.parent_id)) {
                        // this.changeDevelop(row);
                        const ind = this.arr.indexOf(parent);
                        if (this.arr.indexOf(row) < 0) {
                            this.arr.splice(ind + 1, 0, row);
                        }
                        if ([3, 4].includes(row.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        // if ([6].includes(row.type)) {
                        //     row.visible = false;
                        // }
                        if ([5].includes(row.type)) {
                            row.visible = parent.open;
                            row.open = false;
                            this.openChilds(row, row.open);
                        }
                        if ([5].includes(parent.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        if ((this.arr.indexOf(row) > 0) && (!row.visible)) {
                            this.arr.splice(this.arr.indexOf(row), 1);
                        }
                        this.deleteNulls();
                    }
                }
            }
        },
        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },


        // открывает/закрывает ветку до конечного элемента
        async deleteNulls() {
            for (const row of this.arr) {
                if (parseFloat(row.row5)
                    + parseFloat(row.row6)
                    + parseFloat(row.row7)
                    + parseFloat(row.row8)
                    + parseFloat(row.row11) === 0) {
                    row.visible = false;
                }
            }
        }
    }
}
</script>

<style scoped>
.td-numbering {
    padding: 3px 10px !important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400 !important;
    color: #6087A0 !important;
    background-color: #F7F9FC;
}
</style>
