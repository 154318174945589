import { Vue, Component } from 'vue-property-decorator';

import Income from './income.vue';
import Outcome from './outcome.vue';
import Report from './report.vue';
import BipTable from '@/modules/budget/bip/BipTable.vue';
import TreasuryCard from '@/modules/budget/treasury-forms/treasury-card.vue';
import store from "@/services/store";
import PowerBiIframe from "@/components/c-power-bi-iframe/Iframe.vue";

@Component({
    components: {
        BipTable,
        'income': Income,
        'outcome': Outcome,
        'report': Report,
        'treasury-card': TreasuryCard,
        'PowerBiIframe': PowerBiIframe
    }
})
export default class Monitoring extends Vue {
    private income = false;
    private outcome = false;
    private report = false;
    private analytic = false;
    private region = '00'

    private isSelectedTreasuryCard = false;
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    created() {
        Object.keys(this.$router.currentRoute.query).forEach((key) => {
            if (key === 'income') {
                this.income = true;
            }
            if (key === 'outcome') {
                this.outcome = true;
            }
            if (key === 'report') {
                this.report = true;
            }
        });
    }
    mounted() {
        this.region = store.state._instanceCode;
    }

    private selectTreasuryCard(event: any) {
        this.isSelectedTreasuryCard = true;
    }
    private selectedNotTreasuryCard(event: any) {
        this.isSelectedTreasuryCard = false;
    }
}