<template>
    <div class="row card-container">
        <v-widget v-if="incomeKat.id" :data="incomeKat" :section="{}" />
        <v-widget v-if="incomeMonth.id" :data="incomeMonth" :section="{}" />
        <v-widget v-if="incomeRegion.id" :data="incomeRegion" :section="{}" />
    </div>
</template>

<script>
import VWidget from '@/modules/widget-yard/c-widget/v-widget';
import store from '@/services/store';

export default {
    components: {
        'v-widget': VWidget
    },
    name: 'Income',
    data() {
        return {
            year: new Date().getFullYear(),
            // month: new Date().getMonth() - 1,
            region: 450101,
            incomeKat: {},
            incomeMonth: {},
            incomeRegion: {},
            code: store.state._instanceCode
        };
    },
    methods: {
        async getIncomeKat() {
            const response = await fetch(`/api-py/get-request-budg-kat-doh/${this.year}/09/${this.region}`);
            const data = await response.json();
            this.incomeKat = {
                type: 'chart-monitoring',
                id: 111,
                title: data.shortIndicator,
                data: data.items,
                unit: data.unit,
                periodicity: data.periodicity,
                indicatorKeys: [],
                vertical: false
            };
        },
        async getIncomeMonth() {
            const response = await fetch(`/api-py/get-request-budg-month-doh/${this.year}/09/${this.region}`);
            const data = await response.json();
            this.incomeMonth = {
                type: 'chart-monitoring',
                id: 112,
                title: data.shortIndicator,
                data: data.items,
                unit: data.unit,
                periodicity: data.periodicity,
                indicatorKeys: [],
                vertical: true
            };
        },
        async getIncomeRegion() {
            const response = await fetch(`/api-py/get-request-budg-region-doh/${this.year}/09/${this.region}`);
            const data = await response.json();
            this.incomeRegion = {
                type: 'chart-monitoring',
                id: 113,
                title: data.shortIndicator,
                data: data.items,
                unit: data.unit,
                periodicity: data.periodicity,
                indicatorKeys: [],
                vertical: false
            };
        }
    },
    created() {
        if (this.code === 23) {
            this.region = 150101;
        }
        this.getIncomeKat();
        this.getIncomeMonth();
        this.getIncomeRegion();
    }
};
</script>